.appRoot {
  width: 100%;
  min-height: 100vh;
  background: rgb(241, 241, 241);
}

.appHeader {
  width: 100%;
  padding-top: 24px;
  padding-bottom: 24px;
  margin-bottom: 8px;
}

.appTitle {
  width: 100%;
  font-size: 2.5em;
  text-align: center;
  font-weight: bold;
}

.socketState {
  width: 100%;
  text-align: center;
  font-size: 1.0em;
}

.sessionState {
  width: 100%;
  text-align: center;
  font-size: 1.3em;
}

.qrState {
  width: 100%;
  text-align: center;
  font-size: 1.5em;
}

.blinking {
  animation: fade 2s linear infinite;
}

.qrCodeView {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
}

.sessionError {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
}

.codeBig {
  font-size: 1.2em;
}

.hint {
  width: 60%;
  text-align: left;
  align-self: center;
  margin-top: 12px;
  border-radius: 8px;
  padding: 8px;
  background: white;
}

.hintTitle {
  font-size: 1.2em;
  font-weight: bolder;
  margin-bottom: 8px;
}

.titleCenter {
  text-align: center;
  font-size: 1.5em;
}

.hintItem {
  margin-bottom: 8px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
}

.iconCaption {
  vertical-align: middle;
}

.icon {
  vertical-align: middle;
  margin-right: 8px;
}

.qrCode {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 12px;
}

.qrImage {
  padding: 8px;
  background: white;
  border-radius: 8px;
}

.blurry {
    transition: 0.1s;
    filter: blur(5px);
}

.progressbar {
    height: 30px;
    width: 100%;
}

.progressbar > span {
  display: block;
  height: 100%;
  background-color: rgb(43,194,83);
  position: relative;
  overflow: hidden;
  transition: 0.5s;
}

.appFooter {
  margin-top: 24px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.appVersion {
  opacity: 0.2;
  user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -webkit-user-select: none;
}

.appLinks {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 12px;
}

.appLink {
  color: black;
  opacity: 0.5;
  cursor: pointer;
}

.appLink:hover {
  text-decoration: underline;
  opacity: 1;
  transition: 0.5s;
}

.hint-box {
  width: 100%;
  display: flex;
  flex-direction: column;
}

@media only screen and (max-width: 600px) {

  .appTitle {
    font-size: 2.0em;
  }

  .socketState {
    font-size: 0.7em;
  }

  .sessionState {
    font-size: 0.8em;
  }

  .qrState {
    font-size: 1em;
  }

  .hint {
    width: 90%;
  }

  .appLinks {
    margin-top: 8px;
    gap: 12px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}

.cantSeeImage {
  width: 100%;
  text-align: center;
  margin-top: 4px;
  text-decoration: underline;
  opacity: 0.5;
  cursor: pointer;
}

@keyframes fade {
  0%,100% { opacity: 0 }
  50% { opacity: 1 }
}

