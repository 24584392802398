.companion-view-box {
    margin-top: 12px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.companion-view {
    display: flex;
    flex-direction: column;
    align-items: baseline;
    background: white;
    border: 2px solid coral;
    border-radius: 12px;
    padding: 13px;
}

.companion-view-title {
    align-self: center;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    color: black;
    font-size: 1.5em;
    padding: 4px 20px 4px 12px;
    font-weight: bolder;
}

.companion-view-title > .icon {
    font-size: 2em;
    color: coral;
}

.companion-view-line {
    text-align: center;
}

.danger-line {
    color: red;
}